import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./blog.css";
import { useTranslation } from "react-i18next";
import ContactForm from "../Shared/ContactForm";
import parse from "html-react-parser";
import MenuLink from "../Shared/MenuLink";

function ContentComponent({ content }) {
  const options = {
    replace: ({ name, attribs }) => {
      if (name === "a" && attribs && attribs.href) {
        return (
          <Link className="menu__item">
            <MenuLink
              text={"Scout"}
              targetId="contacts"
              disableRouting={true}
            />
          </Link>
        );
      }
    },
  };

  return <div className="post__text">{parse(content.join(""), options)}</div>;
}

function Post() {
  const [currentPost, setCurrentPost] = useState(null);
  const { postId } = useParams();
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("ua");
    fetch("/posts.json")
      .then((response) => response.json())
      .then((data) => {
        const foundPost = data.find((post) => post.id === postId);
        setCurrentPost(foundPost);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [postId]);

  if (!currentPost) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50vh",
          left: "50vw",
          transform: "translateX(-50%)",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div className="blog__container">
      <div className="post">
        <span className="title post__title">{currentPost.title}</span>
        <div className="date item-tariffs__li--disabled">
          {currentPost.date}
        </div>
        <ContentComponent content={currentPost.content} />
      </div>

      <ContactForm country={i18n.language} forBlog={true} />
    </div>
  );
}

export default Post;
