import React, { useEffect, useRef } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import MenuLink from "./MenuLink";
import useBreakpoint from "./UseBreakpoint";
import useScrollWatcher from "./ScrollWatcher";
import { useTranslation } from "react-i18next";
import useClickOutside from "./ClickOutside";

const MainLayout = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [langMenu, setLangMenu] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);

  const [headerRef, isHeaderVisible] = useScrollWatcher();
  const [footerRef, isFooterVisible] = useScrollWatcher();
  const [translationsNeeded, setTranslationsNeeded] = React.useState(true);
  function toggleMobileMenu(state) {
    setMobileMenu(state);
    if (state) document.documentElement.classList.add("menu-open");
    else document.documentElement.classList.remove("menu-open");
  }
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.split("/")[1] === "blog" ||
      location.pathname === "/google-maps-optimization"
    ) {
      setTranslationsNeeded(false);
    } else setTranslationsNeeded(true);
  }, [location.pathname]);

  const langMenuRef = useRef(null);

  useClickOutside(langMenuRef, () => setLangMenu(false));

  const menuJSX = (
    <div className="header__actions">
      {translationsNeeded && (
        <div
          ref={langMenuRef}
          className={`header__languages languages ${langMenu ? "is-open" : ""}`}
        >
          <button
            type="button"
            className="languages__current"
            onClick={() => setLangMenu(!langMenu)}
          >
            {i18n.language}
          </button>
          <ul className="languages__list">
            <li>
              <button
                onClick={() => {
                  setLangMenu(!langMenu);
                  toggleMobileMenu(false);
                  i18n.changeLanguage("ua");
                }}
                className="languages__link"
              >
                <span>UA</span>
                <img src="img/ukraine-flag.svg" alt="UA" />
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setLangMenu(!langMenu);
                  toggleMobileMenu(false);
                  i18n.changeLanguage("es");
                }}
                className="languages__link"
              >
                <span>ES</span>
                <img src="img/spain-flag.svg" alt="ES" />
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setLangMenu(!langMenu);
                  toggleMobileMenu(false);
                  i18n.changeLanguage("en");
                }}
                className="languages__link"
              >
                <span>EN</span>
                <img src="img/united-states.png" alt="EN" />
              </button>
            </li>
          </ul>
        </div>
      )}
      <div onClick={() => toggleMobileMenu(false)}>
        <MenuLink
          className="header__btn button"
          targetId="contacts"
          text={t("header.tryForFree")}
        />
      </div>
      {/*<div className="header__socials">*/}
      {/*  <Link to="https://www.facebook.com/scout.review">*/}
      {/*    <img*/}
      {/*      src="img/icons/facebook.png"*/}
      {/*      className="social-icon"*/}
      {/*      alt="Facebook"*/}
      {/*    />*/}
      {/*  </Link>*/}
      {/*  <Link to="https://www.instagram.com/scout.service/">*/}
      {/*    <img*/}
      {/*      src="img/icons/instagram.png"*/}
      {/*      alt="Instagram"*/}
      {/*      className="social-icon"*/}
      {/*    />*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </div>
  );

  return (
    <div className={`wrapper`} id="header">
      <header
        data-watch=""
        ref={headerRef}
        className={isHeaderVisible ? "header _watcher-view" : "header"}
      >
        <div className="header__container">
          <div className="header__body">
            <Link to="/" className="header__logo">
              <img src="img/logo.png" alt="logo Scout" />
            </Link>
            <div className="header__menu menu">
              <nav className="menu__body">
                <ul className="menu__list">
                  <li
                    className="menu__item"
                    onClick={() => toggleMobileMenu(false)}
                  >
                    <MenuLink
                      text={t("header.platforms")}
                      targetId="platforms"
                    />
                  </li>
                  <li
                    className="menu__item"
                    onClick={() => toggleMobileMenu(false)}
                  >
                    <MenuLink
                      text={t("header.opportunities")}
                      targetId="opportunities"
                    />
                  </li>
                  <li
                    className="menu__item"
                    onClick={() => toggleMobileMenu(false)}
                  >
                    <MenuLink text={t("header.tariffs")} targetId="tariffs" />
                  </li>
                  {i18n.language === "ua" && (
                    <>
                      <li
                        className="menu__item"
                        onClick={() => toggleMobileMenu(false)}
                      >
                        <Link to="/blog" className="menu__link">
                          {t("header.blog")}
                        </Link>
                      </li>
                      <li
                        className="menu__item"
                        onClick={() => toggleMobileMenu(false)}
                      >
                        <Link
                          to="/google-maps-optimization"
                          className="menu__link"
                        >
                          {t("header.googleMapsOptimization")}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
                {!useBreakpoint(767) ? menuJSX : ""}
              </nav>
            </div>
            {useBreakpoint(767) ? menuJSX : ""}
            <button
              type="button"
              onClick={() => toggleMobileMenu(!mobileMenu)}
              className="icon-menu"
            >
              <span></span>
            </button>
          </div>
        </div>
      </header>
      <main style={{ minHeight: "100vh" }}>
        <Outlet />
      </main>
      <footer
        data-watch=""
        ref={footerRef}
        className={isFooterVisible ? "footer _watcher-view" : "footer"}
      >
        <div className="footer__container">
          <div className="footer__body">
            <div className="footer__col">
              <p className="footer__logo">
                <img src="img/logo.png" alt={t("footer.logoAlt")} />
              </p>
              <div className="footer__text">{t("promo.title")}</div>

              <div className="footer__socials" style={{ gap: "1rem" }}>
                <Link to="https://www.facebook.com/scout.review">
                  <img
                    src="img/icons/facebook.png"
                    className="social-icon"
                    alt="Facebook"
                  />
                </Link>
                <Link to="https://www.instagram.com/scout.service/">
                  <img
                    src="img/icons/instagram.png"
                    alt="Instagram"
                    className="social-icon"
                  />
                </Link>
              </div>
            </div>
            <div className="footer__block">
              <div className="footer__menu">
                <div className="footer__title">{t("footer.menuTitle")}</div>
                <MenuLink
                  targetId={"platforms"}
                  className="footer__link"
                  text={t("footer.platforms")}
                />
                <MenuLink
                  targetId={"opportunities"}
                  className="footer__link"
                  text={t("footer.opportunities")}
                />
                <MenuLink
                  targetId={"tariffs"}
                  className="footer__link"
                  text={t("footer.tariffs")}
                />
                {i18n.language === "ua" && (
                  <>
                    <Link to={"/blog"} className="footer__link">
                      {t("footer.blog")}
                    </Link>
                    <Link
                      to={"/google-maps-optimization"}
                      className="footer__link"
                    >
                      {t("footer.googleMapsOptimization")}
                    </Link>
                  </>
                )}
              </div>
              <div className="footer__info">
                <div className="footer__title">
                  {t("footer.usefulInfoTitle")}
                </div>
                {i18n.language === "ua" && (
                  <Link
                    to="/public-offer"
                    target={"_blank"}
                    className="footer__link"
                  >
                    {t("footer.publicOffer")}
                  </Link>
                )}

                <a
                  href={`tel:${t("footer.phoneNumber")}`}
                  className="footer__link"
                >
                  {t("footer.phoneNumber")}
                </a>
                <a
                  href="mailto:scout.rev.esp@gmail.com"
                  className="footer__link"
                >
                  {t("footer.email")}
                </a>
                {/*<p style={{ lineHeight: 1.3 }}>*/}
                {/*  {t("footer.address.title")}:*/}
                {/*  <br />*/}
                {/*  {t("footer.address.line1")}*/}
                {/*  <br />*/}
                {/*  {t("footer.address.line2")}*/}
                {/*</p>*/}
              </div>
            </div>
          </div>

          <div className="footer__copy">© 2024 Scout</div>
        </div>
      </footer>
      <a href="https://t.me/Scout_Telegram" target="_blank">
        <div className="floating-button">
          <img src="img/contacts/telegram.svg" alt="Telegram" />
        </div>
      </a>
    </div>
  );
};

export default MainLayout;
