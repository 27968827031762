import "./optimization.css";
import { useTranslation } from "react-i18next";
import MenuLink from "../Shared/MenuLink";
import useScrollWatcher from "../Shared/ScrollWatcher";
import ContactForm from "../Shared/ContactForm";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Optimization() {
  const [cards, setCards] = useState([]);
  const [advantagesRef, isAdvantagesVisible] = useScrollWatcher();
  const [choiceRef, isChoiceVisible] = useScrollWatcher();
  const [commerceRef, isCommerceVisible] = useScrollWatcher();
  const advantages = [
    {
      title: "Доданні вашого бізнесу на Google Maps",
      text: "Зробіть перший крок до залучення нових клієнтів, додавши свій бізнес на Google Maps! Ми допоможемо вам створити або оптимізувати профіль компанії, щоб ви були помітними для локальних користувачів. Точна інформація, правильні ключові слова та фото зроблять ваш бізнес легко знайденим.",
    },
    {
      title: "Органічному просуванню існуючих точок в Google карті",
      text: "Будьте першими, кого побачать клієнти, шукаючи ваші послуги в Google Maps! Ми оптимізуємо ваш бізнес-профіль, покращуємо видимість, опрацьовуємо відгуки та пишемо новини. Професійне налаштування, актуальні дані, позитивні відгуки — усе, щоб ваш бізнес завжди був на вершині результатів пошуку.",
    },
    {
      title: "Запуском платної реклами на картах (PPC)",
      text: "Забезпечте своєму бізнесу лідируючі позиції на Google Maps за допомогою платної реклами. Ми налаштуємо рекламну кампанію, щоб ваша локація з'являлася на перших рядках пошуку та привертала максимум уваги. Оптимізація бюджету, таргетинг на потрібну аудиторію й повний контроль результатів — усе, щоб ваш бізнес був першим вибором клієнтів.",
    },
    {
      title: "Піднятті репутації локальних точок",
      text: "Ми допоможемо вам збільшити кількість відгуків, опрацьовуємо наявні відгуки та спонукатимемо клієнтів залишати позитивні коментарі. Допомагаємо покращити репутацію вашого локального бізнесу в короткі терміни.",
    },
  ];
  const choices = [
    {
      title: "Оптимізація для легкого знаходження",
      description:
        "Ми ідеально оптимізуємо карточку вашої організації в Google My Business, що дозволить легко знайти ваш бізнес на Google Maps, навіть коли користувачі в русі.",
    },
    {
      title: "Відгуки та рейтинги",
      description:
        "Допомагаємо вам збирати та виводити позитивні відгуки, підвищуючи рейтинг вашого бізнесу та покращуючи його репутацію.",
    },
    {
      title: "Моніторинг та звітність",
      description:
        "Наші інструменти моніторингу та звітності дозволяють вам стежити за взаємодією користувачів з вашим бізнесом на карті.",
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage("ua");
    fetch("/posts.json")
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const { i18n } = useTranslation();
  return (
    <main className="promoting">
      <section className={"promoting__container"}>
        <div className={"optimization-container"}>
          <p className={"title"}>
            <p className="title-gradient">Просування на Google Maps</p>
            <p className="title">з результатом</p>
          </p>
          <div className="optimization-photo opt-photo-sm">
            <img src={"./img/optimization/frame-175.png"} alt="" />
          </div>
          <p>
            Ми робимо ваш бізнес доступним для мільйонів користувачів Google
            Maps. Забезпечте йому вражаючу видимість та конкурентну перевагу.
            Наші експерти по Google My Business ретельно оптимізують карточку
            вашого бізнесу, що дозволить точно та легко знайти вас вашим
            клієнтам.
          </p>
          <div className="benefits tick">
            <p> Складаємо чітку стратегію</p>
            <p> Прозора звітність</p>
            <p> Проводимо попередній аналіз ситуації</p>
            <p> Над проектом працює мінімум 3 фахівця</p>
          </div>
          <MenuLink
            targetId={"contacts"}
            text={"Залишити заявку"}
            className="header__btn button request-button"
            disableRouting={true}
          />
        </div>
        <div className="optimization-photo">
          <img src={"./img/optimization/frame-175.png"} alt="" />
        </div>
      </section>
      <section
        data-watch
        data-watch-threshold="0.5"
        ref={advantagesRef}
        className={
          isAdvantagesVisible ? "advantages _watcher-view" : "advantages"
        }
      >
        <div className={"comforts__container"}>
          <p className={"title"}>Можемо бути вам корисні в:</p>
          <div className={"benefits-container"}>
            {advantages.map((slide, index) => (
              <div className={"advantage"} key={index}>
                <div className="advantages-item" style={{ height: "100%" }}>
                  <div className="advantages-item__head">
                    <div className="advantages-item__num">{index + 1}</div>
                    <h3 className="advantages-item__title">{slide.title}</h3>
                  </div>
                  <div className="advantages-item__text">{slide.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        data-watch
        data-watch-threshold="0.5"
        ref={choiceRef}
        className={isChoiceVisible ? "choice _watcher-view" : "choice"}
      >
        <div className={"choice__container"}>
          <p className={"title choice__title"}> Чому нас обирають</p>
          <div className={"choices"}>
            {choices.map((choice, index) => (
              <div className={"choice-item"} key={index}>
                <p className={"item-num"}>0{index + 1}</p>
                <p className={"choice-item_title"}>{choice.title}</p>
                <p className={"choice-item_desc"}>{choice.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        data-watch
        data-watch-threshold="0.5"
        ref={commerceRef}
        className={isCommerceVisible ? "commerce _watcher-view" : "commerce"}
      >
        <div className={"commerce__container"}>
          <div className={"commerce-col"}>
            <p className={"title"}>
              Отримайте комерційну пропозицію{" "}
              <span className={"title-gradient"}>вже зараз</span>
            </p>
            <div className={"commerce_text tick"}>
              <p>Проводимо аудит поточного стану локації;</p>
              <p>Переглядаємо конкурентів;</p>
              <p>Складаємо оптимальний план дій для розвитку локації;</p>
              <p>Прорахуємо об'єм та вартість.</p>
            </div>
            <MenuLink
              targetId={"contacts"}
              text={"Хочу деталі"}
              className="header__btn button request-button"
              disableRouting={true}
            />
          </div>
          <div className={"commerce-col col-right"}>
            <div className="circle-wrapper">
              <img
                src="img/promo-robot.png"
                alt="Картинка"
                className="robot-image"
              />
              <div className="half-circle"></div>
            </div>
          </div>
        </div>
      </section>
      <section className={"m-0-sm"}>
        <div className={"promotion__container"}>
          <p className={"title"}>Послуги просування на Google карті</p>
          <div className="promotion-action">
            <div className={"promotion-col col-form"}>
              <div className="simple-form">
                <ContactForm isSingle={true} country={i18n.language} />
              </div>
            </div>
            <div className={"promotion-col"}>
              <p>
                <strong>Просування на Google Maps від Scout</strong>
              </p>
              <p>
                Google Maps – це не лише інструмент пошуку потрібного місця або
                вивчення локацій, що знаходяться поблизу вас. Сервіс Google
                Карти приносять додатковий трафік на сайт і підвищують
                лояльність потенційних клієнтів, які знаходяться поруч з вашим
                офісом або авторизованою точкою продаж.
              </p>
              <p>
                Google Карти розраховані на локальний бізнес: перукарні, СТО,
                спортзали, кафе, ресторани, стоматології, приватні школи, салони
                краси тощо. Додавши власну компанію в Google Maps, користувачам
                буде простіше знайти вас, а вам отримати «гарячих» клієнтів.
              </p>
              <p>
                <strong>Який потенціал мають Google карти</strong>
              </p>
              <p>
                Багато підприємців і бізнесменів-початківців нехтують таким
                ефективним інструментом просування бізнесу та залучення
                клієнтів, як Google My Business. А даремно! За допомогою
                просування на Google картах, ваша компанія розміщує релевантну
                інформацію на Google My Business, тим самим підвищуючи власний
                рейтинг у результатах пошуку.
              </p>
              <p>
                Google Maps мають значний потенціал для малого та середнього
                бізнесу. Це ефективний інструмент просування, який дозволяє
                власникам керувати інформацією про свій бізнес у службах Google.
                Завдяки профілю підприємства в Google My Business, ви маєте
                змогу не лише додавати актуальну інформацію (наприклад, адресу,
                номер телефону, години роботи, послуги тощо), а й переглядати
                статистику та спілкуватися з користувачами.
              </p>
              <p>
                <strong>
                  Ось кілька способів розкрутки через Google Карти для компаній
                  з метою збільшення трафіку та виведення бізнесу на новий
                  рівень:
                </strong>
              </p>
              <p>
                <ol>
                  <li>
                    Виділяйтеся серед конкурентів. Перетворюйте людей, які
                    знаходять вас у Пошуку Google і на Maps, на нових клієнтів,
                    створивши персоналізований профіль компанії. Додайте
                    фотографії, пропозиції, публікації тощо, щоб висвітлити
                    важливу інформацію та продемонструвати те, що робить ваш
                    заклад унікальним.
                  </li>
                  <li>
                    Зв’язуйтеся з клієнтами. Діліться з клієнтами актуальною
                    інформацією за допомогою публікацій, пропозицій, прямих
                    повідомлень тощо. Незалежно від того, у вас ресторан,
                    магазин чи надаєте послуги населенню, просування у Google
                    Maps допомагає клієнтам легко та безпосередньо вести з вами
                    діалог, надаючи асортимент продуктів, варіанти замовлення,
                    бронювання, ціни тощо.
                  </li>
                  <li>
                    Приймайте замовлення на доставку та самовивіз їжі. Ви навіть
                    можете додати власне меню, щоб люди могли знайти ваші
                    найкращі страви в один click.
                  </li>
                  <li>
                    Автоматично розміщуйте список своїх товарів у магазині
                    безкоштовно прямо з профілю Google My Business.
                  </li>
                  <li>
                    Пропонуйте свої послуги. Демонструйте список послуг і
                    надайте ціни онлайн, щоб клієнти отримували необхідну
                    інформацію для збільшення лояльності
                  </li>
                  <li>
                    Зрозумійте поведінку клієнтів. Дізнайтеся, за якими
                    ключовими словами люди вас шукають, і отримуйте статистичні
                    дані про дзвінки, відгуки, бронювання тощо.
                  </li>
                </ol>
              </p>
              <p>
                <strong>Переваги просування на Google Maps</strong>
              </p>
              <p>
                Просування у Google Maps – чудовий спосіб збільшення додаткового
                трафіку на локальні запити, залучення відвідувачів і підвищення
                обсягів продажів. Однозначно, відсутність у цьому сервісі
                означає втрату потенційних клієнтів, перш за все, якщо ЦА
                бізнесу – люди, які знаходяться неподалік вашої локації.
              </p>
              <p>
                У пошуковій системі Google відображається лише кілька
                організацій у списку. Щоб побачити решту – необхідно натиснути
                на посилання «Інші місця». Щоб потрапити до трійки, потрібна
                розкрутка Google Maps.
              </p>
              <p>
                Просування в Google Maps – це один з пріоритетних каналів
                просування локального бізнесу. Якщо у вашої компанії є фізична
                адреса, просування допоможе:
              </p>
              <p>
                <ol>
                  <li>Отримати увагу цільової аудиторії.</li>
                  <li>Підвищити локальну видимість організації.</li>
                  <li>Сформувати правильний імідж.</li>
                  <li>Підсилити репутацію і довіру споживачів до бренду.</li>
                  <li>Поліпшити лояльність клієнтів.</li>
                  <li>Мати платформу для комунікації.</li>
                  <li>Отримати додатковий якісний трафік на сайт.</li>
                  <li>Привернути увагу мандрівників.</li>
                </ol>
              </p>
              <p>
                <strong>Як відбувається розкрутка в Google картах</strong>
              </p>
              <p>Додаткова функція Google Карти – платна реклама, а саме:</p>
              <div className={"tick"}>
                <p>
                  Залучення клієнтів поблизу. Вони з великою ймовірністю
                  звернуться до вас, якщо ви пропонуєте товари та послуги.
                </p>
                <p>
                  Розміщення довідкової інформації про організацію. Користувачі
                  бачать адреси філій, відгуки, рейтинг, фото, посилання на сайт
                  і соцмережі.
                </p>
              </div>
              <p>
                Коли користувачі вводять запит у Google.com або Google Картах,
                спочатку вони побачать оголошення з адресою вашої організації.
                Для цього під час налаштування місцевої пошукової реклами
                встановлюється відповідне розширення.
              </p>
              <p>
                Налаштувати пошукові оголошення в Google Maps можна за допомогою
                пошукової кампанії в Google Ads. Для цього потрібно увійти в
                обліковий запис і дотримуватись алгоритму:
              </p>
              <p>
                <ol>
                  <li>Вибрати мету «Відвідування магазинів та акції».</li>
                  <li>Налаштувати цілі, бюджет та додати розширення.</li>
                  <li>
                    Додати адресу: прив’язати сервіс Google Мій бізнес до
                    облікового запису Google Реклами можна через пункт «Створити
                    адресу».
                  </li>
                  <li>
                    Підтвердити дію у «Запитах» у вкладці «Пов’язані облікові
                    записи».
                  </li>
                </ol>
              </p>
              <p>
                {" "}
                Підключати рекламні оголошення можна за наступними типами
                кліків:
              </p>
              <div className={"tick"}>
                <p>«Отримати інформацію про місцезнаходження»;</p>
                <p>«Маршрут проїзду»;</p>
                <p>«Мобільні оголошення з інтерактивним номером телефону»;</p>
                <p>“Переходи” на сайт чи соцмережі.</p>
              </div>
              <p>
                Як аналізувати результати реклами в Google Maps? У Google Ads
                збирається статистика за кліками користувачів у Google Картах та
                релевантності ключових слів.
              </p>
              <p>
                Для цього треба перейти у «Кампанії» → «Групи оголошень» →
                «Оголошення та розширення» → «Ключові слова» або «Сегмент» →
                «Тип click».
              </p>
              <p>
                Регулярно актуалізуйте дані профілю, щоб Google точно визначав
                їхню релевантність запитам користувачів. Заповнюйте кожне поле у
                вкладці «Інформація» та увімкніть персональні рекомендації –
                підказки від Google про те, що варто доповнити для покращення
                позицій у місцевому пошуку.
              </p>
              <p>
                Додайте більше фотографій товарів чи послуг, щоб привернути
                увагу користувачів та зробити оголошення максимально
                привабливими. Важливо врахувати встановлені Google вимоги до
                оптимізації зображень:
              </p>
              <div className="tick">
                <p>формати JPG чи PNG;</p>
                <p>розмір 10-5 МБ;</p>
                <p>роздільна здатність мінімум 720×720 px;</p>
                <p>висока чіткість, мінімум ретуші;</p>
                <p>максимальна відповідність реальному виду об’єктів.</p>
              </div>
              <p>
                Відповідайте на відгуки клієнтів – заохочуйте зворотний зв’язок.
                Так швидше відбудеться виведення до ТОП видачі місцевого пошуку.
                Не залишайте відгуки про свою компанію під чужим ім’ям – за це
                Google може назавжди видалити вас із сервісу.
              </p>
              <p>
                <strong>
                  Чому варто замовити просування в Google Maps від Scout
                </strong>
              </p>
              <p>
                Якщо ви хочете покращити видимість свого бізнесу та залучити до
                25% додаткового трафіку на сайт вашої компанії, радимо замовити
                просування в Google Maps саме в агентстві Scout.
              </p>
              <p>
                Ми залюбки збільшимо присутність вашого бізнесу у пошуку та
                допоможемо вашому потенційним клієнтам знайти вас. Вони будуть
                знаходити у Google Картах за назвою вашої фірми, а також за
                цільовими пошуковими запитами.
              </p>
              <p>
                Наша команда візьме на себе весь фронт робіт, включаючи
                повноцінне заповнення профілю і його розкрутку в локальному
                пошуку. Scout декламує оптимальні ціни на просування і якісне
                виконання робіт з розкрутки вашої фірми в Google Maps.
              </p>
              <p>
                Бажаєте замовити просування або додатково проконсультуватись?
                Зв’яжіться з нашим менеджером і він відповість на всі ваші
                питання.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section data-watch="" data-watch-threshold="0.5">
        <div className="blog-optim__container">
          <div className="services__head cusstom">
            <h4 className="services__subtitle subtitle">НАШ БЛОГ</h4>
            <h2 className="services__title title">
              ВАМ ТАКОЖ МОЖЕ БУТИ ЦІКАВО
            </h2>
          </div>
          <div className="services__body">
            <Swiper
              spaceBetween={50}
              breakpoints={{
                653: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
                1390: {
                  slidesPerView: 4,
                },
              }}
              navigation={{
                nextEl: ".serv-swiper-button-next",
                prevEl: ".serv-swiper-button-prev",
              }}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              className="services__slider"
            >
              {cards.map((post, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => navigate(`/blog/${post.id}`)}
                  className="item-services swiper-slide blog-card-custom"
                >
                  <div
                    className="item-services__img"
                    style={{ transform: "rotate(0)" }}
                  >
                    <img
                      src={`/img/blog/${post.image}`}
                      alt={post.title}
                      style={{ maxHeight: "400px", aspectRatio: "1" }}
                    />
                  </div>
                  <h4
                    className="item-services__title"
                    style={{ marginTop: "-7.5rem" }}
                  >
                    <span className={"blog__card-title_optim"}>
                      {post.title}
                    </span>
                  </h4>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="services__navigation">
              <button type="button" className="serv-swiper-button-prev">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.24408 9.41074C4.91864 9.73618 4.91864 10.2638 5.24408 10.5893L11.9107 17.2559C12.2362 17.5814 12.7638 17.5814 13.0893 17.2559C13.4147 16.9305 13.4147 16.4028 13.0893 16.0774L7.01184 10L13.0893 3.92259C13.4147 3.59715 13.4147 3.06951 13.0893 2.74408C12.7638 2.41864 12.2362 2.41864 11.9107 2.74408L5.24408 9.41074Z"
                    fill="#C4D0E4"
                  />
                </svg>
              </button>
              <button type="button" className="serv-swiper-button-next">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7559 9.41074C15.0814 9.73618 15.0814 10.2638 14.7559 10.5893L8.08926 17.2559C7.76382 17.5814 7.23618 17.5814 6.91075 17.2559C6.58531 16.9305 6.58531 16.4028 6.91075 16.0774L12.9882 10L6.91074 3.92259C6.58531 3.59715 6.58531 3.06951 6.91074 2.74408C7.23618 2.41864 7.76382 2.41864 8.08926 2.74408L14.7559 9.41074Z"
                    fill="#C4D0E4"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
      <ContactForm country={i18n.language} />
    </main>
  );
}

export default Optimization;
