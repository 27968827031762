import CreatePayment from "./CreatePayment";
import CalculateDesiredRating from "../Shared/CalculateDesiredRating";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Accordion } from "react-bootstrap";
import MenuLink from "../Shared/MenuLink";
import useScrollWatcher from "../Shared/ScrollWatcher";
import { useTranslation } from "react-i18next";
import AdvantagesSection from "./AdvantagesSection";
import ContactForm from "../Shared/ContactForm";

function Home() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [calcResult, setCalcResult] = useState(null);
  const advantageSlides = t("advantageSlides", { returnObjects: true });
  const services = t("services", { returnObjects: true });
  const faqs = t("faqs", { returnObjects: true });

  const [promoRef, isPromoVisible] = useScrollWatcher();
  const [calcRef, isCalcVisible] = useScrollWatcher();
  const [platformsRef, isPlatformsVisible] = useScrollWatcher();
  const [opportunitiesRef, isOpportunitiesVisible] = useScrollWatcher();
  const [optionsRef, isOptionsVisible] = useScrollWatcher();
  const [stepsRef, isStepsVisible] = useScrollWatcher();

  const [tariffsRef, isTariffsVisible] = useScrollWatcher();
  const [faqRef, isFaqVisible] = useScrollWatcher();
  const [servicesRef, isServicesVisible] = useScrollWatcher();

  const [basicPlanSelected, setBasicPlanSelected] = useState(2);
  const [proPlanSelected, setProPlanSelected] = useState(2);
  const tariffsBasic = [59, 49, 39];
  const tariffsPro = [79, 69, 59];
  const x = [3, 6, 12];
  const tariffsBodyUA = (
    <div className="tariffs__body">
      <div className="tariffs__item item-tariffs item-tariffs--basic">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title">START 10</h3>
          <div className="item-tariffs__block">
            <p className="item-tariffs__price">
              <span>295</span>грн/відгук
            </p>
            <p className="item-tariffs__text">*2950грн за пакет</p>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">ЩО ВХОДИТЬ В ПАКЕТ</div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Збір 10 відгуків на Google-картах ГАРАНТОВАНО*
            </li>
            <li className="item-tariffs__li">
              Підвищення рейтингу бізнесу за рахунок модерації відгуків
            </li>
            <li className="item-tariffs__li">Захист від спаму</li>
            <li className="item-tariffs__li">Персональний менеджер</li>
            <li className="item-tariffs__li">
              Розробка повідомлень для збору відгуків
            </li>
            <li className="item-tariffs__li  item-tariffs__li--disabled">
              Статистика відгуків
            </li>
            <li className="item-tariffs__li   item-tariffs__li--disabled">
              Аналіз конкурентів
            </li>
            <li className="item-tariffs__li  item-tariffs__li--disabled">
              Аналіз точки на Google-картах та рекомендації покращення
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Інтеграція з вашою CRM
            </li>
          </ul>
          <div className="item-tariffs__note">
            *змінити пакет можливо індивідуально під ваш бізнес
          </div>
          <CreatePayment
            amount={2950}
            currency={"UAH"}
            description={'Пакет "Start 10"'}
          />
        </div>
      </div>
      <div className="tariffs__item item-tariffs item-tariffs--popular">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title">BASIC 30</h3>
          <div className="item-tariffs__block">
            <div className="item-tariffs__price">
              <span>199</span>грн/відгук
            </div>
            <p className="item-tariffs__text">*5970грн за пакет</p>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">ЩО ВХОДИТЬ В ПАКЕТ</div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Збір 30 відгуків на Google-картах ГАРАНТОВАНО*
            </li>
            <li className="item-tariffs__li">
              Підвищення рейтингу бізнесу за рахунок модерації відгуків
            </li>
            <li className="item-tariffs__li">Захист від спаму</li>
            <li className="item-tariffs__li">Персональний менеджер</li>
            <li className="item-tariffs__li">
              Розробка повідомлень для збору відгуків
            </li>
            <li className="item-tariffs__li  item-tariffs__li--disabled">
              Розширена статистика відгуків
            </li>
            <li className="item-tariffs__li  item-tariffs__li--disabled">
              Розширений аналіз конкурентів
            </li>
            <li className="item-tariffs__li  item-tariffs__li--disabled">
              Аналіз точки на Google-картах та рекомендації покращення
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Інтеграція з вашою CRM
            </li>
          </ul>
          <div className="item-tariffs__note">
            *змінити пакет можливо індивідуально під ваш бізнес
          </div>
          <CreatePayment
            amount={5970}
            currency={"UAH"}
            description={'Пакет "Basic 30"'}
          />
        </div>
      </div>
      <div className="tariffs__item item-tariffs item-tariffs--professional">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title">PROFESSIONAL 55</h3>
          <div className="item-tariffs__block">
            <div className="item-tariffs__price">
              <span>179</span>грн/відгук
            </div>
            <p className="item-tariffs__text">*9845грн за пакет</p>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">ЩО ВХОДИТЬ В ПАКЕТ</div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Збір 55 відгуків на Google-картах ГАРАНТОВАНО*
            </li>
            <li className="item-tariffs__li">
              Підвищення рейтингу бізнесу за рахунок модерації відгуків
            </li>
            <li className="item-tariffs__li">Захист від спаму</li>
            <li className="item-tariffs__li">Персональний менеджер</li>
            <li className="item-tariffs__li">
              Розробка повідомлень для збору відгуків
            </li>
            <li className="item-tariffs__li">Розширена статистика відгуків</li>
            <li className="item-tariffs__li">Розширений аналіз конкурентів</li>
            <li className="item-tariffs__li">
              Аналіз точки на Google-картах та рекомендації покращення
            </li>
            <li className="item-tariffs__li">Інтеграція з вашою CRM</li>
          </ul>
          <div className="item-tariffs__note">
            *змінити пакет можливо індивідуально під ваш бізнес
          </div>
          <CreatePayment
            amount={9845}
            currency={"UAH"}
            description={'Пакет "Professional 55"'}
          />
        </div>
      </div>
    </div>
  );
  const tariffsBodyEN = (
    <div className="tariffs__body">
      <div className="tariffs__item item-tariffs item-tariffs--basic">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title">START 10</h3>
          <div className="item-tariffs__block">
            <p className="item-tariffs__price">
              <span>0</span> USD/month
            </p>
            <p className="item-tariffs__text">*9 USD per review</p>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">
            WHAT’S INCLUDED IN THE PACKAGE
          </div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Increase business rating through review moderation
            </li>
            <li className="item-tariffs__li">
              Instant notification of a new review via email
            </li>
            <li className="item-tariffs__li">Spam protection</li>
            <li className="item-tariffs__li">
              Ability to collect reviews via NFC
            </li>
            <li className="item-tariffs__li">
              Custom design of the review collection page
            </li>
            <li className="item-tariffs__li">Personal manager</li>
            <li className="item-tariffs__li">Review statistics</li>
            <li className="item-tariffs__li">Competitor analysis</li>
            <li className="item-tariffs__li">
              Google Maps location analysis and improvement recommendations
            </li>
            <li className="item-tariffs__li">
              Monthly statistics of key indicators for your business on Google
              Maps
            </li>
            <li className="item-tariffs__li">
              Reviews with photos and voice messages
            </li>
            <li className="item-tariffs__li">
              Review collection mailing via Viber/SMS/Email from your account
            </li>
            <li className="item-tariffs__li">
              Development of review collection messages
            </li>
            <li className="item-tariffs__li">
              Tip collection for staff (cashless)
            </li>
            <li className="item-tariffs__li">
              “Individual worker control” function
            </li>
            <li className="item-tariffs__li">
              Selecting the right review collection strategy
            </li>
            <li className="item-tariffs__li">
              Design of POS materials for review collection
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Promo code/discount/gift for a review
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Surveys
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Integration with your CRM
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Chat with the client
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              NPS reporting
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Platform switching feature, allowing multiple reviews from a
              single client to increase ratings on different sites
            </li>
          </ul>
          <div className="item-tariffs__note">
            *Package changes are possible individually for your business
          </div>
          <CreatePayment amount={99} currency={"USD"} description={'"Start"'} />
        </div>
      </div>
      <div className="tariffs__item item-tariffs item-tariffs--popular">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title" id="basicTariffCallsName">
            BASIC
          </h3>
          <div className="item-tariffs__block">
            <div className="item-tariffs__price">
              <span>{tariffsBasic[basicPlanSelected]}</span> USD/month
            </div>
            <div className="choose_container">
              <div className="button_wrapper">
                <button
                  className={`basic_choose_button ${basicPlanSelected === 0 ? "selected" : ""}`}
                  onClick={() => setBasicPlanSelected(0)}
                >
                  3 months
                </button>
                <button
                  className={`basic_choose_button ${basicPlanSelected === 1 ? "selected" : ""}`}
                  onClick={() => setBasicPlanSelected(1)}
                >
                  6 months
                </button>
                <button
                  className={`basic_choose_button ${basicPlanSelected === 2 ? "selected" : ""}`}
                  onClick={() => setBasicPlanSelected(2)}
                >
                  12 months
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">
            WHAT’S INCLUDED IN THE PACKAGE
          </div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Increase business rating through review moderation
            </li>
            <li className="item-tariffs__li">
              Instant notification of a new review via email
            </li>
            <li className="item-tariffs__li">Spam protection</li>
            <li className="item-tariffs__li">
              Ability to collect reviews via NFC
            </li>
            <li className="item-tariffs__li">
              Custom design of the review collection page
            </li>
            <li className="item-tariffs__li">Personal manager</li>
            <li className="item-tariffs__li">Extended review statistics</li>
            <li className="item-tariffs__li">Extended competitor analysis</li>
            <li className="item-tariffs__li">
              Google Maps location analysis and improvement recommendations
            </li>
            <li className="item-tariffs__li">
              Monthly statistics of key indicators for your business on Google
              Maps
            </li>
            <li className="item-tariffs__li">
              Reviews with photos and voice messages
            </li>
            <li className="item-tariffs__li">
              Review collection mailing via Viber/SMS/Email from your account
            </li>
            <li className="item-tariffs__li">
              Development of review collection messages
            </li>
            <li className="item-tariffs__li">
              Tip collection for staff (cashless)
            </li>
            <li className="item-tariffs__li">
              “Individual worker control” function
            </li>
            <li className="item-tariffs__li">
              Selecting the right review collection strategy
            </li>
            <li className="item-tariffs__li">
              Design of POS materials for review collection
            </li>
            <li className="item-tariffs__li">
              Promo code/discount/gift for a review
            </li>
            <li className="item-tariffs__li">Surveys</li>
            <li className="item-tariffs__li">Integration with your CRM</li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Chat with the client
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              NPS reporting
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Platform switching feature, allowing multiple reviews from a
              single client to increase ratings on different sites
            </li>
          </ul>
          <div className="item-tariffs__note">
            *Package changes are possible individually for your business
          </div>
          <CreatePayment
            amount={tariffsBasic[basicPlanSelected] * x[basicPlanSelected]}
            currency={"USD"}
            description={`"Basic" ${x[basicPlanSelected]} months`}
          />
        </div>
      </div>
      <div className="tariffs__item item-tariffs item-tariffs--professional">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title" id="proTariffCallsName">
            PROFESSIONAL
          </h3>
          <div className="item-tariffs__block">
            <div className="item-tariffs__price">
              <span>{tariffsPro[proPlanSelected]}</span> USD/month
            </div>
            <div className="choose_container">
              <div className="button_wrapper">
                <button
                  className={`pro_choose_button ${proPlanSelected === 0 ? "selected" : ""}`}
                  onClick={() => setProPlanSelected(0)}
                >
                  3 months
                </button>
                <button
                  className={`pro_choose_button ${proPlanSelected === 1 ? "selected" : ""}`}
                  onClick={() => setProPlanSelected(1)}
                >
                  6 months
                </button>
                <button
                  className={`pro_choose_button ${proPlanSelected === 2 ? "selected" : ""}`}
                  onClick={() => setProPlanSelected(2)}
                >
                  12 months
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">
            WHAT’S INCLUDED IN THE PACKAGE
          </div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Increase business rating through review moderation
            </li>
            <li className="item-tariffs__li">
              Instant notification of a new review via email
            </li>
            <li className="item-tariffs__li">Spam protection</li>
            <li className="item-tariffs__li">
              Ability to collect reviews via NFC
            </li>
            <li className="item-tariffs__li">
              Custom design of the review collection page
            </li>
            <li className="item-tariffs__li">Personal manager</li>
            <li className="item-tariffs__li">Extended review statistics</li>
            <li className="item-tariffs__li">Extended competitor analysis</li>
            <li className="item-tariffs__li">
              Google Maps location analysis and improvement recommendations
            </li>
            <li className="item-tariffs__li">
              Monthly statistics of key indicators for your business on Google
              Maps
            </li>
            <li className="item-tariffs__li">
              Reviews with photos and voice messages
            </li>
            <li className="item-tariffs__li">
              Review collection mailing via Viber/SMS/Email from your account
            </li>
            <li className="item-tariffs__li">
              Development of review collection messages
            </li>
            <li className="item-tariffs__li">
              Tip collection for staff (cashless)
            </li>
            <li className="item-tariffs__li">
              “Individual worker control” function
            </li>
            <li className="item-tariffs__li">
              Selecting the right review collection strategy
            </li>
            <li className="item-tariffs__li">
              Design of POS materials for review collection
            </li>
            <li className="item-tariffs__li">
              Promo code/discount/gift for a review
            </li>
            <li className="item-tariffs__li">Surveys</li>
            <li className="item-tariffs__li">Integration with your CRM</li>
            <li className="item-tariffs__li">Chat with the client</li>
            <li className="item-tariffs__li">NPS reporting</li>
            <li className="item-tariffs__li">
              Platform switching feature, allowing multiple reviews from a
              single client to increase ratings on different sites
            </li>
          </ul>
          <div className="item-tariffs__note">
            *Package changes are possible individually for your business
          </div>
          <CreatePayment
            amount={tariffsPro[proPlanSelected] * x[proPlanSelected]}
            currency={"USD"}
            description={`"Professional" ${x[proPlanSelected]} months`}
          />
        </div>
      </div>
    </div>
  );
  const tariffsBodyES = (
    <div className="tariffs__body">
      <div className="tariffs__item item-tariffs item-tariffs--basic">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title">START</h3>
          <div className="item-tariffs__block">
            <p className="item-tariffs__price">
              <span>0</span> EUR/mes
            </p>
            <p className="item-tariffs__text">*9 EUR por reseña</p>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">¿QUÉ INCLUYE EL PAQUETE?</div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Mejora de la clasificación del negocio mediante la moderación de
              reseñas
            </li>
            <li className="item-tariffs__li">
              Notificación instantánea de una nueva reseña por correo
              electrónico
            </li>
            <li className="item-tariffs__li">Protección contra el spam</li>
            <li className="item-tariffs__li">
              Posibilidad de recopilar reseñas a través de NFC
            </li>
            <li className="item-tariffs__li">
              Diseño personalizado de página de reseñas
            </li>
            <li className="item-tariffs__li">Gerente personal</li>
            <li className="item-tariffs__li">Estadísticas de reseñas</li>
            <li className="item-tariffs__li">Análisis de competidores</li>
            <li className="item-tariffs__li">
              Análisis del punto en Google Maps y recomendaciones de mejora
            </li>
            <li className="item-tariffs__li">
              Estadísticas mensuales de los indicadores clave de tu negocio en
              Google Maps
            </li>
            <li className="item-tariffs__li">
              Reseñas con fotos y mensajes de voz
            </li>
            <li className="item-tariffs__li">
              Envíos para la recopilación de reseñas en Viber/SMS/Email desde tu
              cuenta
            </li>
            <li className="item-tariffs__li">
              Elaboración de mensajes para la recopilación de reseñas
            </li>
            <li className="item-tariffs__li">
              Recopilación de propinas para el personal (sin efectivo)
            </li>
            <li className="item-tariffs__li">
              Función de "control de empleado individual"
            </li>
            <li className="item-tariffs__li">
              Selección de la estrategia correcta para recopilar reseñas
            </li>
            <li className="item-tariffs__li">
              Plantillas y ayuda en el desarrollo de materiales POS para la
              recopilación de reseñas
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Código promocional/descuento/regalo al cliente por dejar una
              reseña
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Encuestas
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Integración con tu CRM
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Chat con el cliente
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Informes NPS
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Función de cambio de plataformas, con la que puedes recopilar
              varias reseñas de un cliente y mejorar la clasificación en
              diferentes sitios
            </li>
          </ul>
          <div className="item-tariffs__note">
            *es posible cambiar el paquete individualmente para tu negocio
          </div>
          <CreatePayment amount={99} currency={"EUR"} description={'"Start"'} />
        </div>
      </div>
      <div className="tariffs__item item-tariffs item-tariffs--popular">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title" id="basicTariffCallsName">
            BÁSICO
          </h3>
          <div className="item-tariffs__block">
            <div className="item-tariffs__price">
              <span>{tariffsBasic[basicPlanSelected]}</span> EUR/mes
            </div>
            <div className="choose_container">
              <div className="button_wrapper">
                <button
                  className={`basic_choose_button ${basicPlanSelected === 0 ? "selected" : ""}`}
                  onClick={() => setBasicPlanSelected(0)}
                >
                  3 meses
                </button>
                <button
                  className={`basic_choose_button ${basicPlanSelected === 1 ? "selected" : ""}`}
                  onClick={() => setBasicPlanSelected(1)}
                >
                  6 meses
                </button>
                <button
                  className={`basic_choose_button ${basicPlanSelected === 2 ? "selected" : ""}`}
                  onClick={() => setBasicPlanSelected(2)}
                >
                  12 meses
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">¿QUÉ INCLUYE EL PAQUETE?</div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Mejora de la clasificación del negocio mediante la moderación de
              reseñas
            </li>
            <li className="item-tariffs__li">
              Notificación instantánea de una nueva reseña por correo
              electrónico
            </li>
            <li className="item-tariffs__li">Protección contra el spam</li>
            <li className="item-tariffs__li">
              Posibilidad de recopilar reseñas a través de NFC
            </li>
            <li className="item-tariffs__li">
              Diseño personalizado de página de reseñas
            </li>
            <li className="item-tariffs__li">Gerente personal</li>
            <li className="item-tariffs__li">
              Estadísticas avanzadas de reseñas
            </li>
            <li className="item-tariffs__li">
              Análisis avanzado de competidores
            </li>
            <li className="item-tariffs__li">
              Análisis del punto en Google Maps y recomendaciones de mejora
            </li>
            <li className="item-tariffs__li">
              Estadísticas mensuales de los indicadores clave de tu negocio en
              Google Maps
            </li>
            <li className="item-tariffs__li">
              Reseñas con fotos y mensajes de voz
            </li>
            <li className="item-tariffs__li">
              Envíos para la recopilación de reseñas en Viber/SMS/Email desde tu
              cuenta
            </li>
            <li className="item-tariffs__li">
              Elaboración de mensajes para la recopilación de reseñas
            </li>
            <li className="item-tariffs__li">
              Recopilación de propinas para el personal (sin efectivo)
            </li>
            <li className="item-tariffs__li">
              Función de "control de empleado individual"
            </li>
            <li className="item-tariffs__li">
              Selección de la estrategia correcta para recopilar reseñas
            </li>
            <li className="item-tariffs__li">
              Plantillas y ayuda en el desarrollo de materiales POS para la
              recopilación de reseñas
            </li>
            <li className="item-tariffs__li">
              Código promocional/descuento/regalo al cliente por dejar una
              reseña
            </li>
            <li className="item-tariffs__li">Encuestas</li>
            <li className="item-tariffs__li">Integración con tu CRM</li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Chat con el cliente
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Informes NPS
            </li>
            <li className="item-tariffs__li item-tariffs__li--disabled">
              Función de cambio de plataformas, con la que puedes recopilar
              varias reseñas de un cliente y mejorar la clasificación en
              diferentes sitios
            </li>
          </ul>
          <div className="item-tariffs__note">
            *es posible cambiar el paquete individualmente para tu negocio
          </div>
          <CreatePayment
            amount={tariffsBasic[basicPlanSelected] * x[basicPlanSelected]}
            currency={"EUR"}
            description={`"BÁSICO" ${x[basicPlanSelected]} meses`}
          />
        </div>
      </div>
      <div className="tariffs__item item-tariffs item-tariffs--professional">
        <div className="item-tariffs__head">
          <h3 className="item-tariffs__title" id="proTariffCallsName">
            PROFESIONAL
          </h3>
          <div className="item-tariffs__block">
            <div className="item-tariffs__price">
              <span>{tariffsPro[proPlanSelected]}</span> EUR/mes
            </div>
            <div className="choose_container">
              <div className="button_wrapper">
                <button
                  className={`pro_choose_button ${proPlanSelected === 0 ? "selected" : ""}`}
                  onClick={() => setProPlanSelected(0)}
                >
                  3 meses
                </button>
                <button
                  className={`pro_choose_button ${proPlanSelected === 1 ? "selected" : ""}`}
                  onClick={() => setProPlanSelected(1)}
                >
                  6 meses
                </button>
                <button
                  className={`pro_choose_button ${proPlanSelected === 2 ? "selected" : ""}`}
                  onClick={() => setProPlanSelected(2)}
                >
                  12 meses
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="item-tariffs__body">
          <div className="item-tariffs__subtitle">¿QUÉ INCLUYE EL PAQUETE?</div>
          <ul className="item-tariffs__list">
            <li className="item-tariffs__li">
              Mejora de la clasificación del negocio mediante la moderación de
              reseñas
            </li>
            <li className="item-tariffs__li">
              Notificación instantánea de una nueva reseña por correo
              electrónico
            </li>
            <li className="item-tariffs__li">Protección contra el spam</li>
            <li className="item-tariffs__li">
              Posibilidad de recopilar reseñas a través de NFC
            </li>
            <li className="item-tariffs__li">
              Diseño personalizado de página de reseñas
            </li>
            <li className="item-tariffs__li">Gerente personal</li>
            <li className="item-tariffs__li">
              Estadísticas avanzadas de reseñas
            </li>
            <li className="item-tariffs__li">
              Análisis avanzado de competidores
            </li>
            <li className="item-tariffs__li">
              Análisis del punto en Google Maps y recomendaciones de mejora
            </li>
            <li className="item-tariffs__li">
              Estadísticas mensuales de los indicadores clave de tu negocio en
              Google Maps
            </li>
            <li className="item-tariffs__li">
              Reseñas con fotos y mensajes de voz
            </li>
            <li className="item-tariffs__li">
              Envíos para la recopilación de reseñas en Viber/SMS/Email desde tu
              cuenta
            </li>
            <li className="item-tariffs__li">
              Elaboración de mensajes para la recopilación de reseñas
            </li>
            <li className="item-tariffs__li">
              Recopilación de propinas para el personal (sin efectivo)
            </li>
            <li className="item-tariffs__li">
              Función de "control de empleado individual"
            </li>
            <li className="item-tariffs__li">
              Selección de la estrategia correcta para recopilar reseñas
            </li>
            <li className="item-tariffs__li">
              Plantillas y ayuda en el desarrollo de materiales POS para la
              recopilación de reseñas
            </li>
            <li className="item-tariffs__li">
              Código promocional/descuento/regalo al cliente por dejar una
              reseña
            </li>
            <li className="item-tariffs__li">Encuestas</li>
            <li className="item-tariffs__li">Integración con tu CRM</li>
            <li className="item-tariffs__li">Chat con el cliente</li>
            <li className="item-tariffs__li">Informes NPS</li>
            <li className="item-tariffs__li">
              Función de cambio de plataformas, con la que puedes recopilar
              varias reseñas de un cliente y mejorar la clasificación en
              diferentes sitios
            </li>
          </ul>
          <div className="item-tariffs__note">
            *es posible cambiar el paquete individualmente para tu negocio
          </div>
          <CreatePayment
            amount={tariffsPro[proPlanSelected] * x[proPlanSelected]}
            currency={"EUR"}
            description={`"PROFESIONAL" ${x[proPlanSelected]} mesese`}
          />
        </div>
      </div>
    </div>
  );

  return (
    <main className="page">
      <section
        data-watch
        data-watch-threshold="0.3"
        ref={promoRef}
        className={isPromoVisible ? "promo _watcher-view" : "promo"}
        style={{ marginBottom: "0" }}
      >
        <div className="promo__container">
          <div className="promo__body">
            <div className="promo__content">
              <h1 className="promo__title">
                {t("promo.title")}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/a/aa/Google_Maps_icon_%282020%29.svg"
                  style={{
                    height:
                      "clamp(1.875rem, 1.3961786372rem + 2.394106814vw, 3.5rem)",
                  }}
                  alt="Google Maps Icon"
                />
              </h1>
              <div className="promo__text">
                {t("promo.payment")}
                <br />
                {t("promo.noBots")}
              </div>
              <div className="promo__buttons">
                <MenuLink
                  className={"promo__btn button"}
                  targetId={"tariffs"}
                  text={t("promo.learnMore")}
                />
                <MenuLink
                  className={"promo__contact button"}
                  targetId={"contacts"}
                  text={t("promo.contactUs")}
                />
              </div>
            </div>
            <div className="promo__presentation">
              <div
                className="promo__img"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a href="/files/scout_video.mp4">
                  <picture>
                    <source srcSet="/files/button.gif" type="image/gif" />
                    <img
                      className="promo__phone-decor"
                      src="/files"
                      alt="gif"
                      style={{ width: "150px" }}
                    />
                  </picture>
                </a>
              </div>
            </div>
            <div className="promo__decor">
              <img src="img/promo-robot.png" alt="Image" />
            </div>
          </div>
        </div>
      </section>
      <section
        ref={calcRef}
        className={isCalcVisible ? "calc _watcher-view" : "calc"}
        style={{ display: "flex", flexWrap: "wrap", margin: "50px auto" }}
      >
        <div className="calc-container" style={{ textAlign: "center" }}>
          <h1 className="title" style={{ fontSize: "2em" }}>
            {t("reviewsSection.title")}
          </h1>
          <p
            className="steps__item-text"
            style={{ marginTop: "20px", fontSize: "1.4em" }}
          >
            {t("reviewsSection.statisticsIntro")}
          </p>
          <div className="progress-circle__container">
            <div className="progress-circle">
              <div className="progress-circle__inner">
                <b style={{ fontSize: "1.2em" }}>92%</b>
              </div>
            </div>
          </div>
          <p className="steps__item-text" style={{ fontSize: "1.4em" }}>
            {t("reviewsSection.statistics")}
          </p>
        </div>
        <div className="calc-container calc-plus">
          <h2
            className="title"
            style={{ fontSize: "2em", textAlign: "center" }}
          >
            {t("reviewsSection.calculationTitle")}
          </h2>
          <Formik
            initialValues={{
              currentRating: "",
              currentVotes: "",
              desiredRating: "",
            }}
            onSubmit={(values) => {
              setCalcResult(
                CalculateDesiredRating(
                  values.currentRating,
                  values.currentVotes,
                  values.desiredRating,
                ),
              );
            }}
          >
            {({ values }) => (
              <Form className="calculator">
                <label>
                  {t("reviewsSection.currentRatingLabel")}:
                  <Field
                    type="number"
                    min={0}
                    max={5}
                    step={0.01}
                    name="currentRating"
                    value={values.currentRating}
                  />
                </label>
                <label>
                  {t("reviewsSection.currentVotesLabel")}:
                  <Field
                    type="number"
                    min={0}
                    name="currentVotes"
                    value={values.currentVotes}
                  />
                </label>
                <label>
                  {t("reviewsSection.desiredRatingLabel")}:
                  <Field
                    type="number"
                    min={0}
                    max={5}
                    step={0.01}
                    name="desiredRating"
                    value={values.desiredRating}
                  />
                </label>

                <button
                  type="submit"
                  className="calculator_btn header__btn button"
                >
                  {t("reviewsSection.calculateButton")}
                </button>
                <span className="calculator_result">
                  {calcResult
                    ? calcResult !== "invalidValues"
                      ? `${t("reviewsSection.resultMessage", { rating: calcResult[0], votes: calcResult[1] })}`
                      : t("reviewsSection.invalidInput")
                    : ""}
                </span>
              </Form>
            )}
          </Formik>
        </div>
      </section>

      <section
        data-watch
        data-watch-threshold="0.5"
        ref={platformsRef}
        className={isPlatformsVisible ? "platforms _watcher-view" : "platforms"}
        id="platforms"
      >
        <div className="platforms__container">
          <div className="platforms__head">
            <h4 className="platforms__subtitle subtitle">
              {t("platformsSection.subtitle")}
            </h4>
            <h2 className="platforms__title title">
              {t("platformsSection.title")}
            </h2>
          </div>
          <div className="platforms__items">
            <div className="platforms__item">
              <img
                src="img/platforms/gmaps.webp"
                alt={t("platformsSection.altTexts.googleMaps")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/fb.webp"
                alt={t("platformsSection.altTexts.facebook")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/inst.webp"
                alt={t("platformsSection.altTexts.instagram")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/viber.webp"
                alt={t("platformsSection.altTexts.viber")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/whatsapp.webp"
                alt={t("platformsSection.altTexts.whatsapp")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/telegram.webp"
                alt={t("platformsSection.altTexts.telegram")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/hotline.webp"
                alt={t("platformsSection.altTexts.hotline")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/rozetka.webp"
                alt={t("platformsSection.altTexts.rozetka")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/prom.webp"
                alt={t("platformsSection.altTexts.prom")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/booking.webp"
                alt={t("platformsSection.altTexts.booking")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/tripadvisor.webp"
                alt={t("platformsSection.altTexts.tripadvisor")}
              />
            </div>
            <div className="platforms__item">
              <img
                src="img/platforms/otzyvua.webp"
                alt={t("platformsSection.altTexts.otzyvua")}
              />
            </div>
          </div>
        </div>
      </section>

      <section
        data-watch
        data-watch-threshold="0.3"
        ref={opportunitiesRef}
        className={
          isOpportunitiesVisible
            ? "opportunities _watcher-view"
            : "opportunities"
        }
        id="opportunities"
      >
        <section
          data-watch
          data-watch-threshold="0.3"
          ref={opportunitiesRef}
          className={
            isOpportunitiesVisible
              ? "opportunities _watcher-view"
              : "opportunities"
          }
          id="opportunities"
        >
          <div className="opportunities__container">
            <div className="opportunities__head">
              <h4 className="opportunities__subtitle subtitle">
                {t("opportunities.title")}
              </h4>
              <h2 className="opportunities__title title">
                {t("opportunities.subtitle")}
              </h2>
            </div>
            <div className="opportunities__body">
              <div className="opportunities__card card-opportunities">
                <h3 className="card-opportunities__title">
                  {t("opportunities.cards.easyFeedback.title")}
                </h3>
                <div className="card-opportunities__text">
                  {t("opportunities.cards.easyFeedback.text")}
                </div>
                <div className="card-opportunities__img">
                  <picture>
                    <source
                      srcSet="img/opportunities/image-1.webp"
                      type="image/webp"
                    />
                    <img src="img/opportunities/image-1.png" alt="Image" />
                  </picture>
                </div>
              </div>
              <div className="opportunities__card card-opportunities">
                <h3 className="card-opportunities__title">
                  {t("opportunities.cards.negativeProtection.title")}
                </h3>
                <div className="card-opportunities__text">
                  {t("opportunities.cards.negativeProtection.text")}
                </div>
                <div className="card-opportunities__img">
                  <picture>
                    <source
                      srcSet="img/opportunities/image-2.webp"
                      type="image/webp"
                    />
                    <img src="img/opportunities/image-2.png" alt="Image" />
                  </picture>
                </div>
              </div>
              <div className="opportunities__card card-opportunities">
                <h3 className="card-opportunities__title">
                  {t("opportunities.cards.multichannelFeedback.title")}
                </h3>
                <div className="card-opportunities__text">
                  {t("opportunities.cards.multichannelFeedback.text")}
                </div>
                <div className="card-opportunities__img">
                  <picture>
                    <source
                      srcSet="img/opportunities/image-3.webp"
                      type="image/webp"
                    />
                    <img src="img/opportunities/image-3.png" alt="img3" />
                  </picture>
                </div>
              </div>
              <div className="opportunities__card card-opportunities">
                <h3 className="card-opportunities__title">
                  {t("opportunities.cards.statistics.title")}
                </h3>
                <div className="card-opportunities__text">
                  {t("opportunities.cards.statistics.text")}
                </div>
                <div className="card-opportunities__img">
                  <picture>
                    <source
                      srcSet="img/opportunities/image-4.webp"
                      type="image/webp"
                    />
                    <img src="img/opportunities/image-4.png" alt="Image" />
                  </picture>
                </div>
              </div>
              <div className="opportunities__card card-opportunities">
                <h3 className="card-opportunities__title">
                  {t("opportunities.cards.paymentForResults.title")}
                </h3>
                <div className="card-opportunities__text">
                  {t("opportunities.cards.paymentForResults.text")}
                </div>
                <div className="card-opportunities__img">
                  <picture>
                    <source
                      srcSet="img/opportunities/image-5.webp"
                      type="image/webp"
                    />
                    <img src="img/opportunities/image-5.png" alt="Image" />
                  </picture>
                </div>
              </div>
              <div className="opportunities__card card-opportunities">
                <h3 className="card-opportunities__title">
                  {t("opportunities.cards.customization.title")}
                </h3>
                <div className="card-opportunities__text">
                  {t("opportunities.cards.customization.text")}
                </div>
                <div className="card-opportunities__img">
                  <picture>
                    <source
                      srcSet="img/opportunities/image-6.webp"
                      type="image/webp"
                    />
                    <img src="img/opportunities/image-6.png" alt="Image" />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section
        data-watch
        data-watch-threshold="0.3"
        ref={optionsRef}
        className={isOptionsVisible ? "options _watcher-view" : "options"}
      >
        <div className="options__container">
          <div className="options__body">
            <div className="options__content">
              <div className="options__head">
                <h4 className="options__subtitle subtitle">
                  {t("options.subtitle")}
                </h4>
                <h2 className="options__title title">{t("options.title")}</h2>
              </div>
              <ul className="options__list">
                <li className="options__item">{t("options.list.item1")}</li>
                <li className="options__item">{t("options.list.item2")}</li>
                <li className="options__item">{t("options.list.item3")}</li>
                <li className="options__item">{t("options.list.item4")}</li>
                <li className="options__item">{t("options.list.item5")}</li>
              </ul>
            </div>
            <div className="options__img">
              <picture>
                <source srcSet="img/options-image.webp" type="image/webp" />
                <img src="img/options-image.png" alt="Image" />
              </picture>
            </div>
          </div>
        </div>
      </section>

      <section
        data-watch
        data-watch-threshold="0.3"
        ref={stepsRef}
        className={isStepsVisible ? "steps _watcher-view" : "steps"}
      >
        <div className="steps__container">
          <div className="steps__head">
            <h4 className="steps__subtitle subtitle">{t("steps.subtitle")}</h4>
            <h2 className="steps__title title">{t("steps.title")}</h2>
          </div>
          <div className="steps__body">
            <div className="steps__item">
              <div className="steps__item-number">01</div>
              <div className="steps__item-img">
                <picture>
                  <source srcSet="img/steps/image-1.webp" type="image/webp" />
                  <img src="img/steps/image-1.png" alt="Image" />
                </picture>
              </div>
              <div className="steps__item-text">{t("steps.item1")}</div>
            </div>
            <div className="steps__item">
              <div className="steps__item-number">02</div>
              <div className="steps__item-img">
                <picture>
                  <source srcSet="img/steps/image-2.webp" type="image/webp" />
                  <img src="img/steps/image-2.png" alt="Image" />
                </picture>
              </div>
              <div className="steps__item-text">{t("steps.item2")}</div>
            </div>
            <div className="steps__item">
              <div className="steps__item-number">03</div>
              <div className="steps__item-img">
                <picture>
                  <source srcSet="img/steps/image-3.webp" type="image/webp" />
                  <img src="img/steps/image-3.png" alt="Image" />
                </picture>
              </div>
              <div className="steps__item-text">{t("steps.item3")}</div>
            </div>
            <div className="steps__item">
              <div className="steps__item-number">04</div>
              <div className="steps__item-img">
                <picture>
                  <source srcSet="img/steps/image-4.webp" type="image/webp" />
                  <img src="img/steps/image-4.png" alt="Image" />
                </picture>
              </div>
              <div className="steps__item-text">{t("steps.item4")}</div>
            </div>
          </div>
        </div>
      </section>
      <AdvantagesSection advantageSlides={advantageSlides} />

      <section
        data-watch
        data-watch-threshold="0.15"
        ref={tariffsRef}
        className={isTariffsVisible ? "tariffs _watcher-view" : "tariffs"}
        id="tariffs"
      >
        <div className="tariffs__container">
          <div className="tariffs__head">
            <h4 className="tariffs__subtitle subtitle">
              {t("tariffs.select")}
            </h4>
            <h2 className="tariffs__title title">{t("tariffs.title")}</h2>
          </div>

          {i18n.language === "ua"
            ? tariffsBodyUA
            : i18n.language === "en"
              ? tariffsBodyEN
              : i18n.language === "es"
                ? tariffsBodyES
                : ""}
        </div>
      </section>
      <section
        data-watch
        data-watch-threshold="0.5"
        ref={faqRef}
        className={isFaqVisible ? "faq _watcher-view" : "faq"}
      >
        <div className="faq__container">
          <div className="faq__body">
            <Accordion className="spollers">
              {faqs.map((faq, index) => (
                <Accordion.Item
                  className="spollers__item"
                  eventKey={index}
                  key={index}
                >
                  <Accordion.Header className="spollers__title">
                    {faq.title}
                  </Accordion.Header>
                  <Accordion.Body className="spollers__body">
                    {faq.content}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
      <section
        data-watch
        data-watch-threshold="0.5"
        ref={servicesRef}
        className={isServicesVisible ? "services _watcher-view" : "services"}
      >
        <div className="services__container">
          <div className="services__head">
            <h4 className="services__subtitle subtitle">ДЕ САМЕ?</h4>
            <h2 className="services__title title">
              СФЕРИ БІЗНЕСУ ЯКИМ МОЖЕМО ДОПОМОГТИ
            </h2>
          </div>
          <div className="services__body">
            <Swiper
              spaceBetween={50}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
              navigation={{
                nextEl: ".serv-swiper-button-next",
                prevEl: ".serv-swiper-button-prev",
              }}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              className="services__slider"
            >
              {services.map((service, index) => (
                <SwiperSlide key={index} className="item-services swiper-slide">
                  <div className="item-services__img">
                    <img
                      src={`/img/services/services-img-${index + 1}.jpg`}
                      alt={service.title}
                    />
                  </div>
                  <h4 className="item-services__title">{service.title}</h4>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="services__navigation">
              <button type="button" className="serv-swiper-button-prev">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.24408 9.41074C4.91864 9.73618 4.91864 10.2638 5.24408 10.5893L11.9107 17.2559C12.2362 17.5814 12.7638 17.5814 13.0893 17.2559C13.4147 16.9305 13.4147 16.4028 13.0893 16.0774L7.01184 10L13.0893 3.92259C13.4147 3.59715 13.4147 3.06951 13.0893 2.74408C12.7638 2.41864 12.2362 2.41864 11.9107 2.74408L5.24408 9.41074Z"
                    fill="#C4D0E4"
                  />
                </svg>
              </button>
              <button type="button" className="serv-swiper-button-next">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7559 9.41074C15.0814 9.73618 15.0814 10.2638 14.7559 10.5893L8.08926 17.2559C7.76382 17.5814 7.23618 17.5814 6.91075 17.2559C6.58531 16.9305 6.58531 16.4028 6.91075 16.0774L12.9882 10L6.91074 3.92259C6.58531 3.59715 6.58531 3.06951 6.91074 2.74408C7.23618 2.41864 7.76382 2.41864 8.08926 2.74408L14.7559 9.41074Z"
                    fill="#C4D0E4"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <ContactForm country={i18n.language} />
    </main>
  );
}

export default Home;
